@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

.App {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .clickable:hover{
    cursor: pointer;
  }
  
  .submit-form{
    margin-top: 20px;
    font-size: calc(5px + 2vmin);
  }
  
  .resultRet{
    display: flexbox;
    padding: 10px;
    margin: 10px;
    border:1px solid black;
    font-size:  calc(10px + 2vmin);
    font-family: sans-serif
  }
  
  .bold{
    font-weight: bold;
  }
  
  li{
    text-align: left;
  }