.popup {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 0; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.popup-content {
    background-color: #fefefe;
    justify-content: center;
    box-shadow: darkblue;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 35px;
    border: 60px black;
    width: 80%; /* Could be more or less, depending on screen size */
}
/* The Close Button */
.close {
    color: #aaa;
    float: right;
    font-weight: bold;
    font: xx-large;
}
.close:hover,
.close:focus {
    color: rgb(161, 48, 48);
    text-decoration: none;
    cursor: pointer;
}

.llink{
    color: darkblue;
    font-weight: 350;
    font-style: italic;
}

.llink:hover{
    cursor: pointer;
    color: blue;
}

.textarea{
    width: 80%;
}

.recorder{
    justify-content: right;
    outline: darkred;
}