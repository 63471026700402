.App {
  text-align: center;
  align-items: center;
  justify-content: center;
  position: bottom;
}

.App-header {
  background-color:#284780;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-Subheader {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

.clickable:hover{
  cursor: pointer;
}

.submit-form{
  margin-top: 20px;
  font-size: calc(5px + 2vmin);
}


.bold{
  font-weight: bold;
}

li{
  text-align: left;
}

.form-group{
  margin: 30px;
  justify-content: left;
  text-align: left;
}

.subtitle{
  text-align: left;
}

.audbutton{
  text-align: center;
  align-items: center;
}
.link{
  color: blue;
}
.link:hover{
  cursor: pointer;
}
